(function () {
    $(document).ready(function () {
        if ($(window).width() > 996) AOS.init();

        $('.circle').paroller().fadeIn('slow');

        $(".main-menu__trigger").click(function () {
            isMenuOpen() ? closeMenu() : openMenu();
        });

        $('.programme-step__head').click(function () {
            var parent = $(this).parents('.programme-step');
            var trigger = $(this).find('.programme-step__revealer');
            $(parent).toggleClass('active');
            $(trigger).text($(parent).hasClass('active')
                ? 'Ukryj szczegóły'
                : 'Pokaż szczegóły'
            );
        });

        $("a").click(function (event) {
            var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
            if (this.hash !== "" && $(this).attr('href').charAt(0) !== '/' && !isSmoothScrollSupported) {
                event.preventDefault();
                scrollToSection($(this).attr('href'));
            }
        });
    });

    $(window).resize(function () {
        $(window).width() >= 768 ? openMenu() : closeMenu();
    });

    function isMenuOpen () {
        return $('.main-menu__list').hasClass('visible');
    }

    function openMenu () {
        $('.main-menu__list').addClass('visible').fadeIn('slow');
        $('.main-menu__trigger').addClass('is-active');
    }

    function closeMenu () {
        $('.main-menu__list').removeClass('visible').fadeOut('slow');
        $('.main-menu__trigger').removeClass('is-active');
    }

    function scrollToSection(hash) {
        var target_offset = $(hash).length ? $(hash).offset().top - 100 : 0;
        $("html, body").animate({
            scrollTop: target_offset
        }, 400, function () {
            window.location.hash = hash;
        });
    }
})($ || jQuery);
